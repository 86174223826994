<!-- Please remove this file from your project -->
<template>
    <div>
      <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
      <div class="relative z-40 md:hidden" role="dialog" aria-modal="true">
        <!--
          Off-canvas menu backdrop, show/hide based on off-canvas menu state.
    
          Entering: "transition-opacity ease-linear duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "transition-opacity ease-linear duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
    
        <div class="fixed inset-0 z-40 flex">
          <!--
            Off-canvas menu, show/hide based on off-canvas menu state.
    
            Entering: "transition ease-in-out duration-300 transform"
              From: "-translate-x-full"
              To: "translate-x-0"
            Leaving: "transition ease-in-out duration-300 transform"
              From: "translate-x-0"
              To: "-translate-x-full"
          -->
          <div class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
            <!--
              Close button, show/hide based on off-canvas menu state.
    
              Entering: "ease-in-out duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "ease-in-out duration-300"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span class="sr-only">Close sidebar</span>
                <!-- Heroicon name: outline/x-mark -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
    
            <div class="flex flex-shrink-0 items-center px-4">
              <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"> -->
              <img src="../assets/logo.png" @click="$router.push('/')" class="cursor-pointer"/>
            </div>
            <div class="mt-5 h-0 flex-1 overflow-y-auto">
              <nav class="space-y-1 px-2">
                <a @click="$router.push('/')" class="cursor-pointer text-gray-600 hover:bg-green-50 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                  :class="{'bg-green-100': $route.name == 'index'}">
                  <svg class="text-gray-400 mr-3 flex-shrink-0 h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path></svg>
                  Net Zero {{ $route.name }}
                </a>
    
                <a @click="$router.push('/secrreport')" class="cursor-pointer text-gray-600 hover:bg-green-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                  :class="{'bg-green-100 text-gray-900': $route.name == 'SecrReport'}">
                  <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                  SECR Report
                </a>
    
                <a @click="$router.push('/reconciliation')" class="cursor-pointer text-gray-600 hover:bg-green-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                  :class="{'bg-green-100 text-gray-900': $route.name == 'Reconciliation'}">
                  <!-- Heroicon name: outline/users -->
                  <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path></svg>
                  Reconciliation
                </a>
    
                <a @click="$router.push('/generation')" class="cursor-pointer text-gray-600 hover:bg-green-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                  :class="{'bg-green-100 text-gray-900': $route.name == 'Generation'}">
                  <!-- Heroicon name: outline/calendar -->
                  <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"></path></svg>
                  Generation
                </a>
    
                <a @click="$router.push('/consumption')" class="cursor-pointer text-gray-600 hover:bg-green-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                  :class="{'bg-green-100 text-gray-900': $route.name == 'Consumption'}">
                  <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>
                  Consumption
                </a>
    
                <a @click="$router.push('/notifications')" class="cursor-pointer text-gray-600 hover:bg-green-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                  :class="{'bg-green-100 text-gray-900': $route.name == 'Notifications'}">
                  <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
                  Notifications
                </a>
              </nav>
            </div>
          </div>
    
          <div class="w-14 flex-shrink-0">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>
    
      <!-- Static sidebar for desktop -->
      <div class="hidden md:fixed md:inset-y-0 md:flex md:w-48 md:flex-col">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
          <div class="flex flex-shrink-0 items-center px-4">
            <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"> -->
            <img src="../assets/logo.png" @click="$router.push('/')" class="cursor-pointer"/>
          </div>
          <div class="mt-5 flex flex-grow flex-col">
            <nav class="flex-1 space-y-1 px-2 pb-4">
              <!-- Current: "bg-green-100 text-gray-900", Default: "text-gray-600 hover:bg-green-50 hover:text-gray-900" -->
              <a @click="$router.push('/')" class="cursor-pointer rounded-full text-gray-600 hover:bg-green-50 hover:text-gray-900 group py-3 px-4 flex items-center text-sm font-medium"
                :class="{
                  'bg-green-800 text-gray-100': $route.name == 'home',
                  'text-gray-800': $route.name  != 'home'
                  }">
                <svg class="text-gray-400 mr-3 flex-shrink-0 h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                Home 
              </a>
              <a @click="$router.push('/netzero')" class="cursor-pointer rounded-full hover:bg-green-50 hover:text-gray-900 group py-3 px-4 flex items-center text-sm font-medium"
                :class="{
                  'bg-green-800 text-gray-100': $route.name == 'netzero',
                  'text-gray-800': $route.name != 'netzero'
                  }">
                <svg class="text-gray-400 mr-3 flex-shrink-0 h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path></svg>
                Net Zero
              </a>
    
              <a @click="$router.push('/secr-report')" class="cursor-pointer rounded-full text-gray-600 hover:bg-green-50 hover:text-gray-900 group py-3 px-4 flex items-center text-sm font-medium"
                :class="{
                  'bg-green-800 text-gray-100': $route.name == 'secr',
                  'text-gray-800': $route.name  != 'secr'
                  }">
                <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                SECR Report
              </a>
    
              <a @click="$router.push('/reconciliation')" class="cursor-pointer rounded-full text-gray-600 hover:bg-green-50 hover:text-gray-900 group py-3 px-4 flex items-center text-sm font-medium"
                :class="{
                  'bg-green-800 text-gray-100': $route.name == 'reconciliation',
                  'text-gray-800': $route.name  != 'reconciliation'
                  }">
                <!-- Heroicon name: outline/users -->
                <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path></svg>
                Reconciliation
              </a>
    
              <a @click="$router.push('/generation')" class="cursor-pointer rounded-full text-gray-600 hover:bg-green-50 hover:text-gray-900 group py-3 px-4 flex items-center text-sm font-medium"
                :class="{
                  'bg-green-800 text-gray-100': $route.name == 'generation',
                  'text-gray-800': $route.name  != 'generation'
                  }">
                <!-- Heroicon name: outline/calendar -->
                <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"></path></svg>
                Generation
              </a>
    
              <a @click="$router.push('/consumption')" class="cursor-pointer text-gray-600 hover:bg-green-50 hover:text-gray-900 group rounded-full py-3 px-4 flex items-center text-sm font-medium"
                :class="{
                  'bg-green-800 text-gray-100': $route.name == 'consumption',
                  'text-gray-800': $route.name  != 'consumption'
                  }">
                <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>
                Consumption
              </a>
    
              <a @click="$router.push('/notifications')" class="cursor-pointer rounded-full text-gray-600 hover:bg-green-50 hover:text-gray-900 group py-3 px-4 flex items-center text-sm font-medium"
                :class="{
                  'bg-green-800 text-gray-100': $route.name == 'notifications',
                  'text-gray-800': $route.name  != 'notifications'
                  }">
                <svg class="w-6 h-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
                Notifications
              </a>
            </nav>
            <span class="text-xs mb-2">Powered by Datore</span>
          </div>
        </div>
      </div>
    
      <div class="md:pl-48">
        <div class="mx-auto flex w-full flex-col md:px-8 xl:px-0">
          <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white">
            <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
              <span class="sr-only">Open sidebar</span>
              <!-- Heroicon name: outline/bars-3-bottom-left -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
              </svg>
            </button>
            <div class="flex flex-1 justify-between px-4 md:px-0">
              <div class="flex flex-1 items-center">
                <h3 class="font-bold text-2xl">
                  <slot name="header"></slot>
                </h3>
    
                <div class="ml-auto flex items-center ">
                <!-- <button type="button" class="rounded-full mr-1 bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">View settings</span>
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                </button> -->
                <a href="mailto:info@datore.co"  class="rounded-full mr-4 bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd"></path></svg>
                </a>
    
                <!-- Profile dropdown -->
                <div class="relative ml-3">
                  <div>
                    <button type="button" @click="toggleUserDropdown" class="mr-3 flex max-w-xs pr-2 items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                      <span class="sr-only">Open user menu</span>
                      <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                      <span v-if="user && user.attributes" class="font-medium text-gray-700 ml-2">
                        {{user.attributes.email}}
                      </span>
                    </button>
                  </div>
    
                  <!--
                    Dropdown menu, show/hide based on menu state.
    
                    Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                  -->
                  <div @click="toggleUserDropdown" v-if="showUserDropdown" class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
    
                    <a href="#" class="block py-2 px-4 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Profile</a>
    
                    <a href="#" class="block py-2 px-4 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1">Settings</a>
    
                    <a @click="signOut" class="cursor-pointer block py-2 px-4 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
    
          <main class="flex-1">
            <div class="py-6">
              <div class="px-4 sm:px-6 md:px-0">
                <!-- Replace with your content -->
                <div class="py-4">
                  <div class="w-full h-screen px-8">
                    <slot></slot>
                  </div>
                </div>
                <!-- /End replace -->
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    </template>
  <script>
    import { Auth } from 'aws-amplify';
    export default {
      name: 'DefaultLayout',
      data() {
        return {
          showUserDropdown: false,
        }
      },
      computed: {
      user: {
        get() {
          return this.$store.state.auth.user
        },
        set(value) {
          this.$store.commit('auth/setUser', value)
        }
      },
    },
      mounted() {
        console.log("Hello sidebar")
      },
      methods: {
        toggleUserDropdown() {
          this.showUserDropdown = !this.showUserDropdown;
        },
        async signOut() {
          await Auth.signOut()
          this.$router.push('/login')
        }
      },
    }
    </script>
    

    <style>

  </style>
import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth/auth.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sisenseApp: null,
    loadedSisense: false,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth
  }
})

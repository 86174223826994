import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './css/index.css'

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

let sisenseLib = document.createElement('script')
sisenseLib.setAttribute('src', 'https://datorepoc.com/js/frame.js')
sisenseLib.setAttribute('type', 'text/javascript')
sisenseLib.setAttribute('async', 'true')
document.head.appendChild(sisenseLib)




Vue.config.productionTip = false

import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

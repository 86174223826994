<template>
  <div class="pb-12">
        <div class="flex w-full justify-end mb-3">
          <button type="button"  @click="toggleFilters()" class="inline-flex mb-2 items-center rounded-full border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd"></path></svg>
            Filters
          </button>
        </div>

        <div v-show="showFilters" class="fixed top-0 z-30 shadow-lg bg-white py-12 rounded-md h-screen w-80 right-0">
          <h2 class="text-lg mb-2">Filters</h2>

          <svg @click="toggleFilters()" class="w-8 h-8 absolute left-0 top-0 text-gray-700 cursor-pointer m-3 hover:text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
          <div id="filters" class="w-full h-full px-3 py-12"></div>
        </div>
          <div class="grid grid-cols-3 items-start gap-6">
              <div class="_widget-card col-span-3">
                <div id="widget-alert"></div>
              </div>
              <div class="flex flex-col gap-4 col-span-2">
                  <div class="_widget-card">
                    <div class="flex items-center justify-end">
                      <span  @click="selectWidget0Tab(1)" class="mx-1 p-1 font-bold cursor-pointer text-gray-500 hover:text-gray-900">1D</span>
                      <div class="h-5 w-0.5 bg-gray-200"></div>
                      <span  @click="selectWidget0Tab(2)" class="mx-1 p-1 font-bold cursor-pointer text-gray-500 hover:text-gray-900">1W</span>
                      <div class="h-5 w-0.5 bg-gray-200"></div>
                      <span  @click="selectWidget0Tab(3)" class="mx-1 p-1 font-bold cursor-pointer text-gray-500 hover:text-gray-900">1M</span>
                      <!-- <div class="h-5 w-0.5 bg-gray-200"></div>
                      <span  @click="selectWidget0Tab(4)" class="mx-1 p-1 font-bold cursor-pointer text-gray-500 hover:text-gray-900">1Y</span> -->
                    </div>
                    <div v-show="widget0Tab == 1" id="widget0-1"></div>
                    <div v-show="widget0Tab == 2" id="widget0-2"></div>
                    <div v-show="widget0Tab == 3" id="widget0-3"></div>
                    <!-- <div v-show="widget0Tab == 4" id="widget0-4"></div> -->
                    <div class="grid grid-cols-5">
                      <div id="widget2" class="col-span-4"></div>
                      <div id="widget3" class="col-span-1"></div>
                    </div>
                  </div>
                  <div class="_widget-card">
                    <div id="widget4"></div>
                  </div>
                  <div class="_widget-card">
                    <img src="../assets/map.png" alt="">
                  </div>
              </div>

              <div class="flex flex-col gap-4 col-span-1">
                <div class="_widget-card">
                  <div id="widget1"></div>
                </div>
                <div class="_widget-card">
                  <div id="widget6"></div>
                </div>
                <div class="_widget-card">
                  <div id="widget7"></div>
                </div>
              </div>

          </div>

         
      </div>
</template>
<!-- <script type="text/javascript" src="https://datorepoc.com/js/sisense.v1.js"></script> -->

<script>


export default {
name: "HomePage",
data() {
  return {
    selectedTab: 'm2',
    widget0Tab: 1,
    dash: null,
    showFilters: false,
    widgets: [
      {id: '6356d4141949f50039c6fe72', containerId: 'widget0-1'},
      {id: '6356d4141949f50039c6fe75', containerId: 'widget0-2'},
      {id: '6356d4141949f50039c6fe74', containerId: 'widget0-3'},
      // {id: '6356d4141949f50039c6fe71', containerId: 'widget0-4'},

      {id: '63614bbb9fdf510037be2982', containerId: 'widget1'},
      {id: '6356d4141949f50039c6fe78', containerId: 'widget2'},
      {id: '6356d4141949f50039c6fe77', containerId: 'widget3'},
      {id: '6356d44b1949f50039c6febb', containerId: 'widget4'},
      {id: '6356d5091949f50039c6febd', containerId: 'widget5'},

      {id: '63614dc89fdf510037be2988', containerId: 'widget6'},
      {id: '6356d4141949f50039c6fe76', containerId: 'widget7'},
    ],

  }
},
mounted() {
  console.log("Moutned Generation")
  let scriptTest = document.getElementById('sjsscript');

  if(scriptTest == null) {
    let interval = setInterval(() => {
      scriptTest = document.getElementById('sjsscript');
      if(scriptTest && window.sisenseApp.$$serverUrl) {
        // scriptTest.addEventListener('load', () => {
              // setTimeout(() => {
                  this.loadSisense();
              
                  clearInterval(interval)
              // }, 2000);
          // })
      }
    }, 200);
  } else {
    this.loadSisense();
  }
},
updated() {

    // this.loadSisense();

},
methods: {
    selectTab(value) {
      this.selectedTab = value;
      this.dash.refresh();
    },
    selectWidget0Tab(val){
      this.widget0Tab = val;
      this.dash.refresh();
    },  
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    async loadSisense() {
      let self = this;
    
      let app = window.sisenseApp
      console.log('GOT TO APP', app.dashboards)
      app.dashboards.load('6356d4141949f50039c6fe6e')
          .then((dash) => {
            self.widgets.forEach((w) => {
              dash.widgets.get(w.id).container = document.getElementById(w.containerId);
            });
            this.dash = dash.$$model;
            // dash.refresh();
            // dash.renderFilters(document.getElementById('filters'));
            dash.refresh();
            setTimeout(() => {
              dash.refresh();
              dash.renderFilters(document.getElementById('filters'));
              
            }, 1000);
          })
          .catch((e) => {
              console.log('ERROR TO DASHBOARD', e)
              console.error(e);
          });

      app.dashboards.load('636505079fdf510037be2c5c')
          .then((dash) => {
            dash.widgets.get('636505079fdf510037be2c74').container = document.getElementById('widget-alert');
            dash.refresh();
          })

    },
},

}
</script>

<style>
.capitalize {
  text-transform: capitalize;
}

.c11312 .font-size-extraLarge {
  font-size: 10px !important;
}
</style>
<template>
  <div id="app">
    <div  id="sisenseApp">
      
          <!-- <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
          </nav> -->
          <Sidebar v-if="user">
            <router-view/>
          </Sidebar>
          <router-view v-else/>

    </div>

  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue';
import { Auth } from 'aws-amplify';
import axios from 'axios';

export default {
    name: "IndexPage",
    components: { Sidebar },
    computed: {
      user: {
        get() {
          return this.$store.state.auth.user
        },
        set(value) {
          this.$store.commit('auth/setUser', value)
        }
      },
    },
    beforeCreate() {

      let self = this;
      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(function(user) {
          self.user = user
      })
    },
    beforeMount() {
      this.initialise();
    },
    methods: {
      async initialise() {
          let token = await this.getToken();

          console.log("TOKEN666--", token)

          var redirect = "https://datorepoc.com/jwt?jwt=" + token;

          let url = redirect + "&return_to=/js/sisense.js";
          // let url = redirect;

          let authUrl = 'https://datorepoc.com/jwt?jwt='+token+'&return_to=/api/auth/isauth'

          await axios.get(authUrl, { withCredentials: true }).then(() => console.log("got default url"));
          console.log('urll', url)

          let scriptTest = document.getElementById('sjsscript');
          console.log("SCRIPT", scriptTest);

          if(scriptTest == null) {
              let sisenseUrl = 'https://datorepoc.com/js/sisense.js'
              let sisensejsLib = document.createElement('script')
              sisensejsLib.setAttribute('src', sisenseUrl)
              sisensejsLib.setAttribute('type', 'text/javascript')
              sisensejsLib.setAttribute('async', 'true')
              sisensejsLib.setAttribute('id', 'sjsscript')
              document.head.appendChild(sisensejsLib)
              sisensejsLib.onload = () => {
                const Sisense = window.Sisense;
                Sisense.connect('https://datorepoc.com')
                    .then((app) => {
                        window.sisenseApp = app;
                        console.log("&&& --== =CONNECTED SISENSE APP", window.sisenseApp)
                      })
                .catch((e) => {
                    console.log("NOT MOUNTED MAIN", e)
                    console.error(e);
                });
              }
          } 
      },
      async getToken() {
        let token = (await Auth.currentSession()).getAccessToken().getJwtToken();
        console.log("AUTH TOKEN IS", token)
        let responseToken = null;
        await axios.get('https://api.ecosmartai.com/jwt', 
        {
          headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
        }).then((response) => {
          console.log("JWT TOKEN IS", response.data.sisense_jwt)
          responseToken = response.data.sisense_jwt
        });

        return responseToken;
      },
      async getTokenLocal() {
          var jwt = require("jwt-encode");
          console.log("JWT TOKEN---", jwt);
          function makeid(length) {
              var result = "";
              var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
              var charactersLength = characters.length;
              for (var i = 0; i < length; i++) {
                  result += characters.charAt(Math.floor(Math.random() *
                      charactersLength));
              }
              return result;
          }


          
          var payload = {
              iat: Math.floor((new Date().getTime()) / 1000),
              "User.email": "eugen@tailoored.com",
              "User.FirstName": "Eugen",
              "User.LastName": "Iordache",
              jti: makeid(15),
              exp: Math.floor((new Date().getTime() + 1800 * 60000) / 1000),
          };
          var token = jwt(payload, "b51690e012be0a523d905fc3ac4b78390823c6471e0c8602480ed3a6fa7ec92a");
          console.log("TOKEN ISS", token)

          return token;


          
      },
    },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

._widget-card {
    background: white;
    border-radius: 12px;
    min-height: 100px;
    padding: 16px;
    overflow: hidden;
}
</style>

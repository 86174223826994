import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Consumption from '../pages/Consumption.vue'
import NetZero from '../pages/NetZero.vue'
import TestZero from '../pages/TestZero.vue'
import Reconciliation from '../pages/Reconciliation.vue'
import SecrReport from '../pages/SecrReport.vue'
import Generation from '../pages/Generation.vue'
import Notifications from '../pages/Notifications.vue'
import Login from '../pages/Login.vue'
import { Auth } from 'aws-amplify';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/netzero',
    name: 'netzero',
    component: NetZero
  },
  {
    path: '/testzero',
    name: 'testzero',
    component: TestZero
  },
  {
    path: '/reconciliation',
    name: 'reconciliation',
    component: Reconciliation
  },
  {
    path: '/consumption',
    name: 'consumption',
    component: Consumption
  },
  {
    path: '/secr-report',
    name: 'secr',
    component: SecrReport
  },
  {
    path: '/generation',
    name: 'generation',
    component: Generation
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: 'http://localhost:8080',
  routes
})

router.beforeEach(async (to, from, next) => {
  let canAccess = false;


  await Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }).then(function(user) {
      if(user) {
          canAccess = true;
      }
  })
  .catch(err => console.log(err));


  if (to.name !== 'login' && !canAccess) {
      next({ name: 'login' })
  }
  // if the user is not authenticated, `next` is called twice
  next() 
})



export default router

<template>
  <div>
      <div>
        <div class="grid grid-cols-2 gap-6">
            <div v-for="(widget, index) in widgets" :key="'widget'+index" class="_widget-card p-2"
            :class="`col-span-${widget.columns}`">
              <div class="text-green-700 bg-green-50 font-bold capitalize text-3xl font-bold text-center p-3 w-full rounded-md">
                <h3>{{widget.title}}</h3>
              </div>
              <div :id="'widget'+index"></div>
              <div :id="'widget-alert'+index"></div>
            </div>
        </div>
      </div>
  </div>
</template>
<!-- <script type="text/javascript" src="https://datorepoc.com/js/sisense.v1.js"></script> -->

<script>

export default {
  name: "HomePage",
  data() {
    return {
      selectedTab: 'm2',
      widgets: [
        {id: '636505079fdf510037be2c65', alertId: '636505079fdf510037be2c6d', containerId: 'widget0', columns: 1, title: 'CONSUMPTION'},
        {id: '636505079fdf510037be2c70', alertId: '636505079fdf510037be2c6f', containerId: 'widget1', columns: 1, title: 'NET ZERO'},
        {id: '636505079fdf510037be2c73', alertId: '636505079fdf510037be2c74', containerId: 'widget2', columns: 1, title: 'GENERATION'},
        {id: '636505079fdf510037be2c6b', alertId: '636512c09fdf510037be2ce4', containerId: 'widget3', columns: 1, title: 'SECR'},
        {id: '636505079fdf510037be2c72', alertId: '636505079fdf510037be2c71', containerId: 'widget4', columns: 1, title: 'RECONCILIATION'},
        {id: '636505079fdf510037be2c68', alertId: null, containerId: 'widget5', columns: 1, title: 'SUMMARY'},
      ],

    }
  },
  mounted() {
    console.log("Moutned home")
    let scriptTest = document.getElementById('sjsscript');

    if(scriptTest == null) {
      let interval = setInterval(() => {
        scriptTest = document.getElementById('sjsscript');
        if(scriptTest && window.sisenseApp.$$serverUrl) {
          // scriptTest.addEventListener('load', () => {
                // setTimeout(() => {
                    this.loadSisense();
                
                    clearInterval(interval)
                // }, 2000);
            // })
        }
      }, 200);
    } else {
      this.loadSisense();
    }
  },
  updated() {

      // this.loadSisense();

  },
  methods: {
      selectTab(value) {
        this.selectedTab = value;
      },
      async loadSisense() {
        let self = this;
      
        let app = window.sisenseApp
        console.log('GOT TO APP', app.dashboards)
        app.dashboards.load('636505079fdf510037be2c5c')
            .then((dash) => {
              let i=0;
              self.widgets.forEach((w) => {
                dash.widgets.get(w.id).container = document.getElementById(w.containerId);
                if(w.alertId) {
                  dash.widgets.get(w.alertId).container = document.getElementById('widget-alert'+i++);
                }
              });
              dash.refresh();
              setTimeout(() => {
                dash.refresh();
                
              }, 1000);
            })
            .catch((e) => {
                console.log('ERROR TO DASHBOARD', e)
                console.error(e);
            });

      },
  },

}
</script>

<style>
  .capitalize {
    text-transform: capitalize;
  }
</style>
<template>
    <div>
        <div>
          <div class="grid grid-cols-1 gap-6">
              <div v-for="(widget, index) in widgets" :key="'widget'+index" class="_widget-card p-2">

                <div :id="widget.containerId"></div>
              </div>
          </div>
        </div>
    </div>
  </template>
  <!-- <script type="text/javascript" src="https://datorepoc.com/js/sisense.v1.js"></script> -->
  
  <script>
  
  export default {
    name: "NotificationsPage",
    data() {
      return {
        widgets: [
          {id: '6372d06e9fdf510037be34af', containerId: 'widget0', columns: 1},
          {id: '6372d5149fdf510037be34c4', containerId: 'widget1', columns: 1},
          {id: '6372d26f9fdf510037be34bb', containerId: 'widget2', columns: 1},
        ]
      }
    },
    mounted() {
      console.log("Moutned notifications")
      let scriptTest = document.getElementById('sjsscript');
  
      if(scriptTest == null) {
        let interval = setInterval(() => {
          scriptTest = document.getElementById('sjsscript');
          if(scriptTest && window.sisenseApp.$$serverUrl) {
            // scriptTest.addEventListener('load', () => {
                  // setTimeout(() => {
                      this.loadSisense();
                  
                      clearInterval(interval)
                  // }, 2000);
              // })
          }
        }, 200);
      } else {
        this.loadSisense();
      }
    },
    updated() {
  
        // this.loadSisense();
  
    },
    methods: {
        selectTab(value) {
          this.selectedTab = value;
        },
        async loadSisense() {
          let self = this;
        
          let app = window.sisenseApp
          console.log('GOT TO APP', app.dashboards)
          app.dashboards.load('63728d489fdf510037be34a0')
              .then((dash) => {
                let i=0;
                self.widgets.forEach((w) => {
                  dash.widgets.get(w.id).container = document.getElementById(w.containerId);
                  if(w.alertId) {
                    dash.widgets.get(w.alertId).container = document.getElementById('widget-alert'+i++);
                  }
                });
                dash.refresh();
                setTimeout(() => {
                  dash.refresh();
                  
                }, 1000);
              })
              .catch((e) => {
                  console.log('ERROR TO DASHBOARD', e)
                  console.error(e);
              });
  
        },
    },
  
  }
  </script>
  
  <style>
    .capitalize {
      text-transform: capitalize;
    }
  </style>
<template>
  <div>
      <div class="flex w-full justify-end mb-3">
        <button type="button"  @click="toggleFilters()" class="inline-flex mb-2 items-center rounded-full border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd"></path></svg>
          Filters
        </button>
      </div>
      <div v-show="showFilters" class="fixed top-0 z-30 shadow-lg bg-white py-12 rounded-md h-screen w-80 right-0">
          <h2 class="text-lg mb-2">Filters</h2>

          <svg @click="toggleFilters()" class="w-8 h-8 absolute left-0 top-0 text-gray-700 cursor-pointer m-3 hover:text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
          <div id="filters" class="w-full h-full px-3 py-12"></div>
        </div>
      <span class="col-span-2 col-span-3 col-span-5"></span>
      <div class="_widget-card w-full mb-4">
          <div id="widget-alert"></div>
        </div>
      <div class="mb-4 rounded-md overflow-hidden">
        <img src="../assets/cons1.png" alt="">
      </div>
      <span class="isolate mt-8 mb-4 inline-flex rounded-md shadow-sm">
        <button type="button" @click="selectTab('m2')" class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
        :class="{ 'bg-green-300': selectedTab == 'm2' }">
          Per M2
        </button>
        <button type="button"  @click="selectTab('day')" class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
          :class="{ 'bg-green-300': selectedTab == 'day' }">
          Per Week Day
        </button>
        <button type="button"  @click="selectTab('type')" class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
        :class="{ 'bg-green-300': selectedTab == 'type' }">
          Per Energy Type
        </button>
      </span>

      <div>

        <!-- M2 -->
        <span v-show="selectedTab == 'm2'">
          <div class="grid grid-cols-7 gap-6">

              <div v-for="(widget, index) in widgetsM2" :key="'widget'+index" class="_widget-card p-2"
              :class="`col-span-${widget.columns}`">
                <div class="bg-gray-100 font-bold text-left p-3 w-full rounded-md">
                  <h3>{{widget.title}}</h3>
                </div>
                <div :id="'widget'+index"></div>
              </div>
          </div>
        </span>

        <!-- Day -->
        <span v-show="selectedTab == 'day'">
          <img src="../assets/cons2.png" alt="">
        </span>

        <!-- Type -->
        <span v-show="selectedTab == 'type'">
          <div class="grid grid-cols-3 gap-6">

            <div v-for="(widget, index) in widgetsType" :key="'widget'+index" class="_widget-card p-2"
              :class="`col-span-${widget.columns}`">
                <div class="bg-gray-100 font-bold text-left p-3 w-full rounded-md">
                  <h3>{{widget.title}}</h3>
                </div>
                <div :id="'widget2_'+index"></div>
              </div>
          </div>
        </span>
      </div>
  </div>
</template>
<!-- <script type="text/javascript" src="https://datorepoc.com/js/sisense.v1.js"></script> -->

<script>

// import axios from 'axios';
// TOKEN
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjYyNzgyODUuMjEwNzc2NiwiVXNlci5lbWFpbCI6ImV1Z2VuQHRhaWxvb3JlZC5jb20iLCJVc2VyLkZpcnN0TmFtZSI6IkV1Z2VuIiwiVXNlci5MYXN0TmFtZSI6IklvcmRhY2hlIiwianRpIjoicmFuZG9tIiwiZXhwIjoxNjY4ODcwMjg1LjIxMDc3NjZ9.KutoyYOREQRcH2Qdla1ZiOILt7DvUck4y6bwIaquYuE

export default {
  name: "ConsumptionPage",
  data() {
    return {
      showFilters: false,
      selectedTab: 'm2',
      widget0: null,
      widget1: null,
      widget2: null,
      widget3: null,
      widget4: null,
      widget2_0: null,
      widget2_1: null,
      widgetsM2: [
          { id: '6356c6fc1949f50039c6fde5', containerId: 'widget0', columns: 2, title: ''},
          { id: '6356c6ff1949f50039c6fde7', containerId: 'widget1', columns: 3, title: ''},
          { id: '6356c70f1949f50039c6fde9', containerId: 'widget2', columns: 2, title: ''},
          { id: '6356c7151949f50039c6fdeb', containerId: 'widget3', columns: 2, title: ''},
          { id: '6356c7181949f50039c6fded', containerId: 'widget4', columns: 2, title: '' },
          { id: '6356c71c1949f50039c6fdef', containerId: 'widget5', columns: 3, title: ''},
      ],
      widgetsType: [
          { id: '6356c7291949f50039c6fdf3', containerId: 'widget2_0', columns: 1, title: ''},
          { id: '6356c72c1949f50039c6fdf5', containerId: 'widget2_1', columns: 1, title: ''},
      ],

    }
  },
  mounted() {
    console.log("Moutned consumption")
    let scriptTest = document.getElementById('sjsscript');
    console.log("Moutned consumption", scriptTest)

  
    if(scriptTest == null) {
      let interval = setInterval(() => {
        scriptTest = document.getElementById('sjsscript');
        if(scriptTest && window.sisenseApp.$$serverUrl) {
          console.log("INTERVALED SISENSE APP--", window.sisenseApp.$$serverUrl)
      
                // setTimeout(() => {
            this.loadSisense();
        
            clearInterval(interval)
                // }, 100);
       
        }
      }, 200);
    } else {
      this.loadSisense();
    }
  },
  updated() {

      // this.loadSisense();

  },
  methods: {
      selectTab(value) {
        this.selectedTab = value;
      },
      toggleFilters() {
        this.showFilters = !this.showFilters;
      },
      async loadSisense() {
        let self = this;
        console.log(self)
                let app = window.sisenseApp
                console.log('GOT TO APP', app.dashboards)
                app.dashboards.load('63514dd976f8d20036237475')
                    .then((dash) => {
                      self.widgetsM2.forEach((w) => {
                        dash.widgets.get(w.id).container = document.getElementById(w.containerId);
                        w.title = dash.widgets.get(w.id).$$model.title
                      });

                      console.log("WIDGETS - m2", self.widgetsM2)
                      self.widgetsType.forEach((w) => {
                        dash.widgets.get(w.id).container = document.getElementById(w.containerId);
                        w.title = dash.widgets.get(w.id).$$model.title
                      });

                      dash.refresh();
                        setTimeout(() => {
                          dash.refresh();
                          dash.renderFilters(document.getElementById('filters'));
                        }, 1000);
                    })
                    .catch((e) => {
                        console.log('ERROR TO DASHBOARD', e)
                        console.error(e);
                    });
              
          app.dashboards.load('636505079fdf510037be2c5c')
            .then((dash) => {
              dash.widgets.get('636505079fdf510037be2c6d').container = document.getElementById('widget-alert');
              dash.refresh();
            })
           
      },
  },

}
</script>

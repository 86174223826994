<template>
  <div>
      <div>
      <div class="flex w-full justify-end mb-3">
        <a href="https://drive.google.com/uc?export=download&id=1INnZjCzlnYg7B1UK3XSRyuIF6pRZX_Og" download class="inline-flex cursor-pointer mr-3 mb-2 items-center rounded-full border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
          Download SECR Example Report
        </a>
        <button type="button"  @click="toggleFilters()" class="inline-flex mb-2 items-center rounded-full border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg class="w-6 h-6 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd"></path></svg>
          Filters
        </button>
      </div>
      <div v-show="showFilters" class="fixed top-0 z-30 shadow-lg bg-white py-12 rounded-md h-screen w-80 right-0">
          <h2 class="text-lg mb-2">Filters</h2>

          <svg @click="toggleFilters()" class="w-8 h-8 absolute left-0 top-0 text-gray-700 cursor-pointer m-3 hover:text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
          <div id="filters" class="w-full h-full px-3 py-12"></div>
        </div>
        <div class="grid grid-cols-4 gap-6" style="align-items: flex-start;">
          <div class="col-span-3 flex flex-col gap-4">
            <div class="_widget-card">
              <div id="widget0"></div>
            </div>
            <div class="_widget-card">
              <div id="widget1"></div>
            </div>
            <div class="_widget-card">
              <div id="widget2"></div>
            </div>
          </div>
          <div class="_widget-card">
              <div id="widget3"></div>
          </div>
        </div>
      </div>
  </div>
</template>
<!-- <script type="text/javascript" src="https://datorepoc.com/js/sisense.v1.js"></script> -->

<script>

export default {
  name: "HomePage",
  data() {
    return {
      showFilters: false,
      widgets: [
        {id: '637bd33263818d00384aa159', containerId: 'widget0'},
        {id: '637bd3fb63818d00384aa15e', containerId: 'widget1'},
        {id: '637bd8a163818d00384aa1e9', containerId: 'widget2'},
        {id: '637c01a063818d00384aa34f', containerId: 'widget3'},
      ],

    }
  },
  mounted() {
    console.log("Moutned home")
    let scriptTest = document.getElementById('sjsscript');

    if(scriptTest == null) {
      let interval = setInterval(() => {
        scriptTest = document.getElementById('sjsscript');
        if(scriptTest && window.sisenseApp.$$serverUrl) {
          // scriptTest.addEventListener('load', () => {
                // setTimeout(() => {
                    this.loadSisense();
                
                    clearInterval(interval)
                // }, 2000);
            // })
        }
      }, 200);
    } else {
      this.loadSisense();
    }
  },
  updated() {

      // this.loadSisense();

  },
  methods: {
      selectTab(value) {
        this.selectedTab = value;
      },
      toggleFilters() {
        this.showFilters = !this.showFilters;
      },
      async loadSisense() {
        let self = this;
      
        let app = window.sisenseApp
        console.log('GOT TO APP', app.dashboards)
        app.dashboards.load('637bcef363818d00384aa157')
            .then((dash) => {
              self.widgets.forEach((w) => {
                dash.widgets.get(w.id).container = document.getElementById(w.containerId);
              });
              dash.refresh();
              setTimeout(() => {
                dash.refresh();
                dash.renderFilters(document.getElementById('filters'));
              }, 1000);
            })
            .catch((e) => {
                console.log('ERROR TO DASHBOARD', e)
                console.error(e);
            });

      },
  },

}
</script>

<style>
  .capitalize {
    text-transform: capitalize;
  }
</style>
<template>
  <div class="pb-12">
    <div class="flex w-full justify-end mb-3">
        <button type="button"  @click="toggleFilters()" class="inline-flex mb-2 items-center rounded-full border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd"></path></svg>
          Filters
        </button>
      </div>
      <div v-show="showFilters" class="fixed top-0 z-30 shadow-lg bg-white py-12 rounded-md h-screen w-80 right-0">
          <h2 class="text-lg mb-2">Filters</h2>

          <svg @click="toggleFilters()" class="w-8 h-8 absolute left-0 top-0 text-gray-700 cursor-pointer m-3 hover:text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
          <div id="filters" class="w-full h-full px-3 py-12"></div>
        </div>
      <div>
          <!-- <div id="filters" class="h-80"></div> -->
          <div class="grid grid-cols-4 items-start gap-6">
            <div class="_widget-card col-span-4">
              <div id="widget-alert"></div>
            </div>
             <div class="_widget-card col-span-4">
                <div id="widget0"></div>
             </div>
             <div class="col-span-4 flex w-full items-center justify-center">
              <span class="isolate mb-1 mt-4 inline-flex rounded-md shadow-sm">
                <button type="button" @click="selectTab(1)" class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
                :class="{ 'bg-green-300': selectedTab == 1 }">
                % Variance (kWh)
                </button>
                <button type="button"  @click="selectTab(2)" class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
                  :class="{ 'bg-green-300': selectedTab == 2 }">
                  % Variance (£)
                </button>
                <button type="button"  @click="selectTab(3)" class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
                :class="{ 'bg-green-300': selectedTab == 3 }">
                NCC Breakdowns
                </button>
              </span>
             </div>
             <div v-show="selectedTab == 1" class="_widget-card col-span-1">
                <div id="widget1_1"></div>
             </div>
             <div v-show="selectedTab == 1" class="_widget-card col-span-3">
                <div id="widget1_2"></div>
             </div>
             <div v-show="selectedTab == 1" class="_widget-card col-span-4">
                <div id="widget1_3"></div>
             </div>
             <div v-show="selectedTab == 2" class="_widget-card col-span-4">
                <div id="widget2_1"></div>
             </div>

             <div v-for="(item, index) in widgetsGrid" :key="'widget-gr-'+index" v-show="selectedTab == 3" class="_widget-card col-span-1">
                <div :id="'widget3_'+index"></div>
             </div>
             

             <div v-show="selectedTab == 3" class="col-span-4 flex w-full items-center justify-center">
              <span class="isolate mb-1 mt-4 inline-flex rounded-md shadow-sm">
                <button type="button" @click="selectSubTab(1)" class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
                :class="{ 'bg-green-300': selectedSubTab == 1 }">
                BSUoS
                </button>
                <button type="button"  @click="selectSubTab(2)" class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
                  :class="{ 'bg-green-300': selectedSubTab == 2 }">
                 CfD
                </button>
                <button type="button"  @click="selectSubTab(3)" class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
                :class="{ 'bg-green-300': selectedSubTab == 3 }">
               Feed in Tarrif
                </button>
                <button type="button"  @click="selectSubTab(4)" class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-10 py-4 text-sm font-medium text-gray-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500"
                :class="{ 'bg-green-300': selectedSubTab == 4 }">
               DuoS (RAG)
                </button>
              </span>
             </div>
             <div  v-show="selectedSubTab == 1 && selectedTab == 3" class="_widget-card justify-self-center col-span-3">
              <div id="widget0-1"></div>
             </div>
             <div  v-show="selectedSubTab == 2 && selectedTab == 3" class="_widget-card justify-self-center col-span-3">
              <div id="widget0-2"></div>
             </div>
             <div  v-show="selectedSubTab == 3 && selectedTab == 3" class="_widget-card justify-self-center col-span-3">
              <div id="widget0-3"></div>
             </div>
             <div  v-show="selectedSubTab == 4 && selectedTab == 3" class="_widget-card justify-self-center col-span-3">
              <div id="widget0-4"></div>
             </div>
          </div>
      </div>
  </div>
</template>
<!-- <script type="text/javascript" src="https://datorepoc.com/js/sisense.v1.js"></script> -->

<script>


export default {
name: "ReconciliationPage",
data() {
  return {
    selectedTab: 1,
    selectedSubTab: 1,
    dash: null,
    showFilters: false,
    widgets: [
        { id: '63590d8d1949f50039c7002a', containerId: 'widget0'},
        { id: '63590d8d1949f50039c70015', containerId: 'widget1_1'},
        { id: '63590d8d1949f50039c70016', containerId: 'widget1_2'},
        { id: '63590d8d1949f50039c70017', containerId: 'widget1_3'},
        { id: '63590d8d1949f50039c70018', containerId: 'widget2_1'},
    ],
    widgetsGrid: [
        { id: '63590d8d1949f50039c70026'},
        { id: '63590d8d1949f50039c7001a'},
        { id: '63590d8d1949f50039c7001b'},
        { id: '63590d8d1949f50039c7001c'},
        { id: '63590d8d1949f50039c70027'},
        { id: '63590d8d1949f50039c7001d'},
        { id: '63590d8d1949f50039c7001e'},
        { id: '63590d8d1949f50039c7001f'},
        { id: '63590d8d1949f50039c70028'},
        { id: '63590d8d1949f50039c70020'},
        { id: '63590d8d1949f50039c70021'},
        { id: '63590d8d1949f50039c70022'},
        { id: '63590d8d1949f50039c70029'},
        { id: '63590d8d1949f50039c70023'},
        { id: '63590d8d1949f50039c70024'},
        { id: '63590d8d1949f50039c70025'},
    ],
    widgetsSubgrid: [
        {id: '635ffdad9fdf510037be2718', containerId: 'widget0-1'},
        {id: '635ffdbd9fdf510037be271e', containerId: 'widget0-2'},
        {id: '635ffdcf9fdf510037be2722', containerId: 'widget0-3'},
        {id: '635ffde99fdf510037be2726', containerId: 'widget0-4'},

    ]

  }
},
mounted() {
  let scriptTest = document.getElementById('sjsscript');

  if(scriptTest == null) {
    let interval = setInterval(() => {
      scriptTest = document.getElementById('sjsscript');
      if(scriptTest && window.sisenseApp.$$serverUrl) {
        // scriptTest.addEventListener('load', () => {
              // setTimeout(() => {
                  this.loadSisense();
              
                  clearInterval(interval)
              // }, 2000);
          // })
      }
    }, 200);
  } else {
    this.loadSisense();
  }
},
updated() {

    // this.loadSisense();

},
methods: {
    selectTab(value) {
      this.selectedTab = value;
      this.dash.refresh();
    },
    selectSubTab(value) {
      this.selectedSubTab = value;
      this.dash.refresh();
    },
    toggleFilters() {
        this.showFilters = !this.showFilters;
      },
    async loadSisense() {
      let self = this;
    
      let app = window.sisenseApp
      console.log('GOT TO APP', app.dashboards)
        app.dashboards.load('63590d8d1949f50039c70014')
          .then((dash) => {
            self.dash = dash.$$model;
            self.widgets.forEach((w) => {
              dash.widgets.get(w.id).container = document.getElementById(w.containerId);
            });
            self.widgetsSubgrid.forEach((w) => {
              dash.widgets.get(w.id).container = document.getElementById(w.containerId);
            });
            let i = 0;
            self.widgetsGrid.forEach((w) => {
              dash.widgets.get(w.id).container = document.getElementById('widget3_'+i++);
            });
            // dash.refresh();
            // dash.renderFilters(document.getElementById('filters'));
            dash.refresh();
            setTimeout(() => {
                dash.refresh();
                dash.renderFilters(document.getElementById('filters'));
                
              }, 1000);
          })
          .catch((e) => {
              console.log('ERROR TO DASHBOARD', e)
              console.error(e);
          });

          app.dashboards.load('636505079fdf510037be2c5c')
            .then((dash) => {
              dash.widgets.get('636505079fdf510037be2c71').container = document.getElementById('widget-alert');
              dash.refresh();
            })

    },
},

}
</script>

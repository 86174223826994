<template>
    <div>
        <div id="sisenseApp">
            <div id="widget1"></div>
            <div id="widget2"></div>
            <div id="widget3"></div>
            <div id="widget4"></div>
        </div>
    </div>
</template>
<!-- <script type="text/javascript" src="https://datorepoc.com/js/sisense.v1.js"></script> -->

<script>

// TOKEN
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjYyNzgyODUuMjEwNzc2NiwiVXNlci5lbWFpbCI6ImV1Z2VuQHRhaWxvb3JlZC5jb20iLCJVc2VyLkZpcnN0TmFtZSI6IkV1Z2VuIiwiVXNlci5MYXN0TmFtZSI6IklvcmRhY2hlIiwianRpIjoicmFuZG9tIiwiZXhwIjoxNjY4ODcwMjg1LjIxMDc3NjZ9.KutoyYOREQRcH2Qdla1ZiOILt7DvUck4y6bwIaquYuE

import axios from 'axios';

export default {
    name: "NetZeroPage",
    mounted() {
       this.initialise();    
    },
    methods: {
        async initialise() {
            let token = await this.getToken();
            var redirect = "https://datorepoc.com/jwt?jwt=" + token;

            let url = redirect + "&return_to=/js/sisense.js";
            // let url = redirect;

            let authUrl = 'https://datorepoc.com/jwt?jwt='+token+'&return_to=/api/auth/isauth'

            await axios.get(authUrl, { withCredentials: true }).then(() => console.log("got default url"));
            console.log('urll', url)

            let sisenseUrl = 'https://datorepoc.com/js/sisense.js'
            let sisensejsLib = document.createElement('script')
            sisensejsLib.setAttribute('src', sisenseUrl)
            sisensejsLib.setAttribute('type', 'text/javascript')
            sisensejsLib.setAttribute('async', 'true')
            document.head.appendChild(sisensejsLib)

            sisensejsLib.onload = () => {
                const Sisense = window.Sisense;
                Sisense.connect('https://datorepoc.com')
                    .then((app) => {
                        window.sisenseApp = app;
                        console.log('GOT TO APP', app.dashboards)
                        app.dashboards.load('63514dd976f8d20036237475')
                            .then((dash) => {
                                console.log("MODEL--",dash.$$model);
                                console.log('GOT TO DASHBOARD', dash)
                                dash.widgets.get('635914c91949f50039c70080').container = document.getElementById("widget1");
                                dash.widgets.get('6356c6ff1949f50039c6fde7').container = document.getElementById("widget2");
                                dash.refresh();
                            })
                            .catch((e) => {
                                console.log('ERROR TO DASHBOARD', e)
                                console.error(e);
                            });
                    })
                    .catch((e) => {
                        console.log("NOT MOUNTED MAIN", e)
                        console.error(e);
                    });
            }
        },
        async getToken() {
            var jwt = require("jwt-encode");
            console.log("JWT TOKEN---", jwt);
            function makeid(length) {
                var result = "";
                var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() *
                        charactersLength));
                }
                return result;
            }


            
            var payload = {
                iat: Math.floor((new Date().getTime()) / 1000),
                "User.email": "eugen@tailoored.com",
                "User.FirstName": "Eugen",
                "User.LastName": "Iordache",
                jti: makeid(15),
                exp: Math.floor((new Date().getTime() + 1800 * 60000) / 1000),
            };
            var token = jwt(payload, "b51690e012be0a523d905fc3ac4b78390823c6471e0c8602480ed3a6fa7ec92a");
            console.log("TOKEN ISS", token)

            return token;


           
        }
    }

}
</script>

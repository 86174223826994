<template>
    <div class="flex flex-col items-center justify-center">
      <img alt="EcoSmartAI logo" class="mt-12" src="../assets/logo.png">
      <amplify-authenticator>
      <div>
   
        <amplify-sign-out></amplify-sign-out>
      </div>
    </amplify-authenticator>
    </div>
  </template>
  
  <script>
  
    import {
      onAuthUIStateChange
    } from '@aws-amplify/ui-components';
  
    export default {
    
      name: 'AuthStateApp',
      created() {
        this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
          this.authState = authState;
          this.user = authData;
        });
      },
      data() {
        return {
          // user: undefined,
          authState: undefined,
          unsubscribeAuth: undefined,
        };
      },
      computed: {
        user: {
          get() {
            return this.$store.state.auth.user
          },
          set(value) {
            this.$store.commit('auth/setUser', value)
          }
        },
      },
      beforeDestroy() {
        this.unsubscribeAuth();
      },
      methods: {
        showDocuments() {
          this.$router.push('/')
        }
      },
    };
  </script>